import axios from '@/common/js/request'
export function checkUserSave(data){ // 添加档案
    const checkUserSave = axios({
        url: '/checkUser/save',
        method: 'post',
        data
    })
    return checkUserSave
}
export function checkUserUpdate(data){ // 编辑档案
    const checkUserUpdate = axios({
        url: '/checkUser/update',
        method: 'post',
        data
    })
    return checkUserUpdate
}
export function checkUserSelect(params){ // 档案管理查询
    const checkUserSelect = axios({
        url: '/checkUser/select',
        method: 'get',
        params
    })
    return checkUserSelect
}
export function checkUserDelete(params){ // 删除档案
    const checkUserDelete = axios({
        url: '/checkUser/delete',
        method: 'get',
        params
    })
    return checkUserDelete
}
export function selectByExcel(params){ // 档案管理导出
    const selectByExcel = axios({
        url: '/checkUser/selectByExcel',
        method: 'get',
        params
    })
    return selectByExcel
}
export function downloadUserExcel(params){ // 下载档案excel模板
    const downloadUserExcel = axios({
        url: '/checkUser/downloadUserExcel',
        method: 'get',
        params
    })
    return downloadUserExcel
}
export function selectAddrPullDown(params){ // 行政区域下拉
    const selectAddrPullDown = axios({
        url: '/checkAddr/selectAddrPullDown',
        method: 'get',
        params
    })
    return selectAddrPullDown
}
export function selectDictListByPid(params){ // 字典
    const selectDictListByPid = axios({
        url: '/dict/selectDictListByPid',
        method: 'get',
        params
    })
    return selectDictListByPid
}


