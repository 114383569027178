<template>
  <div>
    <div class="contain1">
      <div>
        行政区域
        <el-select
          clearable
          filterable
          size="mini"
          v-model="search.addr"
          class="m1150 select230"
          placeholder="行政区域"
          @change="inquire"
        >
          <el-option
            v-for="item in addrList"
            :key="item.id"
            :label="item.addrName"
            :value="item.addrCode"
          >
          </el-option>
        </el-select>

        关键字
        <el-input
          size="mini"
          type="text"
          v-model="search.keyword"
          placeholder="用户姓名/联系电话/表号/地址"
          class="m1150 input340"
        ></el-input>
        <el-button
          size="mini"
          type="primary"
          class="ml20"
          icon="el-icon-search"
          @click="inquire"
          >查询</el-button
        >
        <el-button
          size="mini"
          class="ml20"
          icon="el-icon-refresh"
          @click="refresh"
          >重置</el-button
        >
      </div>
    </div>

    <div class="contain2">
      <div class="btn-top">
        <div class="left">
          <el-button size="mini" type="warning" icon="" @click="delItems"
            >批量删除</el-button
          >
        </div>
        <div class="right">
          <el-button
            size="mini"
            type="primary"
            class="ml20"
            icon=""
            @click="openForm"
            >添加</el-button
          >
          <el-button
            size="mini"
            type="primary"
            class="ml20"
            icon=""
            @click="exportUserExcel"
            >导出用户档案</el-button
          >
          <el-button size="mini" type="primary" @click="ToLeadClick" class="ml20"
            >导入用户档案</el-button
          >
        </div>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#f5f7fa' }"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="center"
          width="55"
        >
        </el-table-column>
        <el-table-column prop="userName" label="户主姓名"> </el-table-column>
        <el-table-column prop="userPhone" label="户主手机号"> </el-table-column>
        <el-table-column prop="userIdnumber" label="户主身份证号">
        </el-table-column>
        <el-table-column prop="tableNumber" label="表号"> </el-table-column>
        <el-table-column prop="addr" label="行政区域"> </el-table-column>
        <el-table-column prop="detailedAddress" label="详细地址" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" align="left">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              style="color: red"
              @click="delRow(scope.row)"
              >删除</el-button
            >
            <el-button
              type="text"
              size="small"
              style="color: yellowgreen"
              @click="goCheckBefore(scope.row.id)"
              >历次安检</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="text-align: center; margin: 15px 0 100px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="search.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <!-- 弹窗 -->
      <el-dialog
        @closed="reset"
        :title="formTitle === 1 ? '添加档案' : '编辑档案'"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
      >
        <el-form
          :model="form"
          ref="ruleForm"
          label-width="120px"
          size="medium"
          :rules="rules"
        >
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="户主姓名" prop="userName">
                <el-input
                  v-model="form.userName"
                  placeholder="请填写户主姓名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="户主手机号" prop="userPhone">
                <el-input
                  v-model="form.userPhone"
                  placeholder="请填写联系电话，多个用逗号分割"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="身份证号码" prop="userIdnumber">
                <el-input
                  v-model="form.userIdnumber"
                  placeholder="请填写身份证号码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="表号" prop="tableNumber">
                <el-input
                  v-model="form.tableNumber"
                  placeholder="请填写表号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="紧急联系人" prop="emergencyContact">
                <el-input
                  v-model="form.emergencyContact"
                  placeholder="请填写紧急联系人姓名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="联系人手机号" prop="emergencyContactPhone">
                <el-input
                  v-model="form.emergencyContactPhone"
                  placeholder="请填写联系人手机号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="改造类型" prop="reformType">
                <el-select
                  clearable
                  filterable
                  v-model="form.reformType"
                  placeholder="请选择"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in changeTypeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="房屋类型" prop="houseType">
                <el-select
                  clearable
                  filterable
                  v-model="form.houseType"
                  placeholder="请选择"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in houseTypeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="开户日期" prop="openDate">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.openDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="行政区域" prop="addr">
                <el-select
                  clearable
                  filterable
                  v-model="form.addr"
                  placeholder="行政区域"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in addrList"
                    :key="item.addrCode"
                    :label="item.addrName"
                    :value="item.addrCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="详细地址" prop="detailedAddress">
                <el-input
                  v-model="form.detailedAddress"
                  placeholder="请输入详细地址"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveForm" v-if="formTitle === 1"
            >确 定</el-button
          >
          <el-button type="primary" @click="editForm" v-if="formTitle === 2"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <el-dialog width="30%" center :visible.sync="ToLeadBool" title="请选择">
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="exportUserFile"
            >下载导入模板</el-button
          >
          <el-upload
            class="upload-demo"
            :action="baseURL + '/checkUser/importExcel'"
            :show-file-list="false"
            accept=".xls,.xlsx"
            :data="{ type: search.usertype }"
            :on-success="handleSuccess"
          >
            <el-button style="margin-left: 40px" type="">选择文件导入</el-button>
          </el-upload>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
 
<script>
import {
  checkUserSelect,
  checkUserSave,
  checkUserUpdate,
  checkUserDelete,
  selectByExcel,
  downloadUserExcel,
  selectAddrPullDown,
  selectDictListByPid
} from "../../RequestPort/fileManage/userFile.js";
import getNowFormatDate from "./../../common/js/nowDate.js";
export default {
  name: "userFile",
  data() {
    return {
      search: {
        addr: "",
        keyword: "",
        current: 1,
        size: 100,
      },
      findObj: {}, // 查询和翻页对象
      total: 0,
      formTitle: 1,
      dialogFormVisible: false,
      form: {
        userName: "",
        userPhone: "",
        userIdnumber: "",
        tableNumber: "",
        emergencyContact: "",
        emergencyContactPhone: "",
        reformType: "",
        houseType: "",
        openDate: "",
        addr: "",
        detailedAddress: "",
      },
      tableData: [],
      rules: {
        userName: [
          { required: true, message: "请填写户主姓名", trigger: "blur" },
        ],
        userPhone: [
          { required: false, message: "请填写联系电话", trigger: "blur" },
        ],
        userIdnumber: [
          { required: false, message: "请填写身份证号码", trigger: "blur" },
        ],
        tableNumber: [
          { required: false, message: "请填写表号", trigger: "blur" },
        ],
        emergencyContact: [
          { required: false, message: "请填写紧急联系人姓名", trigger: "blur" },
        ],
        emergencyContactPhone: [
          { required: false, message: "请填写联系人手机号", trigger: "blur" },
        ],
        reformType: [
          { required: false, message: "请选择改造类型", trigger: "change" },
        ],
        houseType: [
          { required: false, message: "请选择房屋类型", trigger: "change" },
        ],
        openDate: [
          { required: false, message: "请选择开户日期", trigger: "change" },
        ],
        addr: [
          { required: true, message: "请选择行政区域", trigger: "change" },
        ],
        detailedAddress: [
          { required: false, message: "请输入详细地址", trigger: "change" },
        ],
      },
      chosedItems: [],
      addrList: [],
      baseURL: "",
      ToLeadBool: false,
      changeTypeList: [],
      houseTypeList: []
    };
  },
  mounted() {
    this.objCopy()
    this.getAddrList();
    this.getUserList();
    this.getDict(353).then(data => {
      this.changeTypeList = data
    })  // 改造类型
    this.getDict(366).then(data => {
      this.houseTypeList = data
    })  // 房屋类型
    this.baseURL = this.$service.defaults.baseURL;
  },
  methods: {
    getUserList() {
      console.log(this.findObj);
      checkUserSelect(this.findObj).then((res) => {
        const { records, total } = res.data;
        this.tableData = records;
        this.total = total;
        console.log(records.length, res.data.pages);
        if (records.length === 0 && res.data.pages >= 1) {
          this.search.current -= 1;
          this.objCopy()
          this.getUserList();
        }
      });
    },
    objCopy () {
      for (let key in this.search){
        this.findObj[key] = this.search[key];
      }
    },
    goCheckBefore(id) {
      this.$router.push({
        path: "checkBefore",
        query: {
          id: id,
        },
      });
    },
    exportUserExcel() {
      let data = {};
      for (let key in this.search) {
        if (key != "current" && key != "size") {
          data[key] = this.search[key];
        }
      }
      selectByExcel(data).then((res) => {
        if (res) {
          const blob = new Blob([res], { type: "application/x-xls" });
          // const fileName = "民用用户数据.xls";
          const fileName = "MYDA" + getNowFormatDate() + ".xls";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        }
      });
    },
    ToLeadClick() {
      this.ToLeadBool = !this.ToLeadBool;
    },
    handleSuccess(res) {
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.getUserList();
      } else {
        this.$message.error(res.msg);
      }
      this.ToLeadBool = false;
    },
    exportUserFile() {
      downloadUserExcel({ type: this.search.usertype }).then((res) => {
        window.location.href = res.data;
        this.ToLeadBool = false;
      });
    },
    refresh() {
      this.search = {
        addr: "",
        keyword: "",
        current: 1,
        size: 100,
      };
      this.objCopy()
      this.getUserList();
    },
    delItems() {
      if (this.chosedItems.length === 0) {
        this.$message.error("请先勾选要删除的数据");
        return false;
      }
      let ids = [];
      this.chosedItems.forEach((item) => {
        ids.push(item.id);
      });
      this.$confirm(`是否确认删除这${ids.length}条数据?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        checkUserDelete({ ids: ids.toString() }).then((res) => {
          this.$message.success(res.msg);
          this.getUserList();
        });
      });
    },
    handleSelectionChange(e) {
      this.chosedItems = e;
      console.log(this.chosedItems);
    },
    editForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          checkUserUpdate(this.form).then((res) => {
            this.$message.success(res.msg);
            if (res.code == 200) {
              this.getUserList();
              this.dialogFormVisible = false;
            }
          });
        }
      });
    },
    saveForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          checkUserSave(this.form).then((res) => {
            this.$message.success(res.msg);
            if (res.code == 200) {
              this.getUserList();
              this.dialogFormVisible = false;
            }
          });
        }
      });
    },
    reset() {
      if (this.$refs.ruleForm !== undefined) {
        this.$refs["ruleForm"].resetFields();
      }
      if (this.form.id) delete this.form.id;
      if (this.form.deleteflag) delete this.form.deleteflag;
    },
    openForm() {
      this.formTitle = 1;
      this.dialogFormVisible = true;
      this.reset();
    },
    edit(row) {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.formTitle = 2;
        let keys = Object.keys(row);
        keys.forEach((item) => {
          item == 'addr' ? this.form.addr = row.addrName : this.form[item] = row[item];
        });
      });
    },
    delRow(row) {
      this.$confirm("是否删除此条数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        checkUserDelete({ ids: row.id }).then((res) => {
          this.$message.success(res.msg);
          this.getUserList();
        });
      });
    },
    inquire() {
      this.search.current = 1;
      this.objCopy()
      this.getUserList();
    },
    indexMethod(index) {
      return (this.search.current - 1) * this.search.size + index + 1;
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.objCopy()
      this.getUserList();
    },
    handleSizeChange(val) {
      this.search.current = 1
      this.search.size = val;
      this.objCopy()
      this.getUserList();
    },
    /**
     * 所属行政区字典
     */
    getAddrList() {
      selectAddrPullDown().then((res) => {
        this.addrList = res.data;
      });
    },
    async getDict(id) {
      let data = await selectDictListByPid({pid: id}).then(res => {
        return res.data
      })
      return Promise.resolve(data)
    }
  },
};
</script>
 
<style scoped lang="less">
::v-deep.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
</style>